<template>
<div id="LevelExamIntro">  
  <Header/>
  <CommonAlert2Func ref="commonAlert2Func" :title="title"  :content="content" :btnnameup="btnNameUp" :btnnamedown="btnNameDown" :onfuncup="onFuncUp" :onfuncdown="onFuncDown"/>
<body class=" bg-white">
    <nav class="navbar bg-white fixed-top nicole-noshadow">
      <div class="container">
        <a class="navbar-brand text-bold me-0 col-4  text-card-big" rel="tooltip" data-placement="bottom" @click="onBack">
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          
        </a>             
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" >
        </a> 
      </div>
    </nav> 
    <div class="nicolespace"></div>
    <div class="nicole-member-center">
      <div class="container text-center mt-2 mb-4">
          <div class="minpicmb">
             <img src="assets/img/5mintext.png" class="minpic ">  
          </div>
          <div class="minpicmb">
             <img src="assets/img/yellow_pen.png" class="minpicword">  
          </div>
          <div class="minpicmb">
             <img src="assets/img/test1.png" class="testapic">  
         </div>
          <button type="button" class="btn btn-lg  btn-success rounded-pill w-95   text-s mb-2 mb-md-4" data-bs-dismiss="modal" @click="startTest()">開始測試</button>
          <button type="button" class="btn btn-lg btn-outline-success rounded-pill w-95 text-s  nicole-noshadow" data-bs-dismiss="modal" @click="openHistory()">查看歷史紀錄</button>
       </div> 
     </div>
<div class="nicolespace"></div>  
    
</body>
  <Footer/>
</div>
</template>


<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CommonAlert2Func from '@/components/CommonAlert2Func.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";

export default {
    name: 'LevelExamIntro',
    components: {
        Header,
        Footer,
        CommonAlert2Func,
    },
    data () {
      return { 
        // api setting
        setDebugMode:false ,
        apiDebug: false,
        debugMode: false,
        apiName: "",

        // data
        levelTestWaitDays: "30",
        isLevelTest: "N",
        backUrl: "",
        
        // Alert
        title: "",
        content: "",
        btnNameUp: "",
        btnNameDown: "",
        onFuncUp: Function,
        onFuncDown: Function,
      }
    },
    methods: {
      openLogin(){
        this.$JsBridge.callHandler('openVueLoginView',JSON.stringify({backUrl:"LevelExamIntro", type:""}), this.openLoginCallback);
        this.backUrl = "";
      },
      openLoginCallback(data){

      },
      setOnBack(){
            this.$JsBridge.callHandler('setOnback', '', this.onBack);
        },
      onBack() {
        if (this.$route.params.back != undefined && this.$route.params.back != "" ) {
          this.$router.push(this.$route.params.back);
        } else if (this.$route.query.back != undefined && this.$route.query.back != "" ) {
          if (this.$route.query.browser == "web") {
            this.$JsBridge.callHandler('openWebView', "/"+this.$route.query.back, this.openWebVueCallback);
          } else {
            this.$router.push(this.$route.query.back);
          }
        } else {
          this.$router.push({
            name: "Home"
          });
        }
      },
      openWebVueCallback(){

      },
      startTest(){
        if (this.isLevelTest == 'Y') {
          this.$router.push({
            name: "LevelExamIntroReciprocal",
            params:{
              back: "LevelExamIntro"
            }
          });
        } else {
          this.title = "距離前次測驗，尚未滿 " + this.levelTestWaitDays + " 天";
          this.btnNameUp = "自訂等級";
          this.btnNameDown = "之後再測";
          this.onFuncUp = this.openCustomLevelSelect;
          this.onFuncDown = null;
          this.$refs.commonAlert2Func.onShow();
        }
      },
      openCustomLevelSelect(){
        this.$router.push({
          name: "CustomLevelSelect",
          query:{
            back: "LevelExamIntro",
            beforeBack: this.back
          }
        });
      },
      openHistory(){
        this.$router.push({
          name: "LevelExamHistory",
          params:{
            back: "LevelExamIntro"
          }
        });
      },
      getAPI(){
        this.apiName="page_level_exam_intro";
        oAC.getAllRecord(oAC.getApiParam(this), {uid: this.$GlobalData.uid}).then(res => {
            if(res && res.record && res.record.length > 0 && res.record[0]["is_level_test"] != undefined){  
              this.isLevelTest = res.record[0].is_level_test;
            }
            if(res && res.level_test_wait_days && res.level_test_wait_days != ""){
              this.levelTestWaitDays = res.level_test_wait_days;
            }
          }
        ).catch((err)=> {
            oltConsole.log(err);
        });
      }
    },
    created(){  
      if (this.$route.query.uid != undefined && this.$route.query.uid != "" ) {
        this.$GlobalData.uid = this.$route.query.uid;
      }

      if (this.$route.params.back != undefined && this.$route.params.back != "" ) {
        this.backUrl = this.$route.params.back;
      } else if (this.$route.query.back != undefined && this.$route.query.back != "" ) {
        this.backUrl = this.$route.query.back;
      }

      if (this.$JsBridge.isAndroid) {
          this.setOnBack();
      }
      this.getAPI();
    }
};
</script>

